<template>
  <div>
    <div v-show="isShow" class="box_pop">
    <div class="title">{{ realTitle }} &nbsp
      <svg @click="closeEventFunction" class="btn_close h-6 w-6"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" style="width:22px">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
    <div class="pop_cont">
      <span v-html="realContents"></span>
      <slot :name="'contents'">
      </slot>
      <div v-if="realType === constant.type.alert">
        <slot :name="'alert'">
          <div class="btn_flex">
          <button @click="closeEventFunction" class="k-button k-button-icon mgt10">확인</button>
          </div>
        </slot>
      </div>
<!--      <div v-else-if="realType === constant.type.confirm">-->
<!--        <slot :name="'confirm'">-->
<!--          <button @click="close">확인</button>-->
<!--          <button @click="close">취소</button>-->
<!--        </slot>-->
<!--      </div>-->
    </div>
  </div>
  <div v-show="isShow" class="dimmed"></div>
  </div>
</template>

<script>
export default {
  name: 'PopupForLanding',
  props: {
    show : {
      type : Boolean,
      default : false
    },
    title : {
      type : String,
      default: '',
    },
    contents : {
      type : String,
      default: '',
    },
    type : {
      type : String,
      default: '03',
      validator : function(value) {
        if(!!value){
          return ['01', '03'].indexOf(value) !== -1
        }
      }
    },
  },
  watch : {
    show : function (newValue, oldValue){
      this.isShow = this.show
    }
  },
  created () {
    const vm = this
    vm.isShow = vm.show
    vm.realType = vm.type
    vm.realContents = vm.contents
    vm.realTitle = vm.title
    vm.closeEventFunction = function (){
      vm.close()
    }
  },
  methods : {
    alert : function (contents, closeCallback){
      const vm = this
      vm.realType = vm.constant.type.alert
      vm.realContents = contents
      vm.open()
      if(!!closeCallback && typeof closeCallback === 'function'){
        vm.closeEventFunction = function (){
          closeCallback()
          vm.close()
        }
      }
    },
    info : function (contents, closeCallback){
      const vm = this
      vm.realType = vm.constant.type.info
      vm.realContents = contents
      vm.open()
      if(!!closeCallback && typeof closeCallback === 'function'){
        vm.closeEventFunction = function (){
          closeCallback()
          vm.close()
        }
      }
    },
    // confirm : function (contents, callback1, callback2, closeCallback){
    //   const vm = this
    //   vm.realType = vm.constant.type.confirm
    //   vm.realContents = contents
    //   vm.open()
    //   if(!!callback1 && typeof callback1 === 'function'){
    //     callback1()
    //   }
    //   if(!!callback2 && typeof callback2 === 'function'){
    //     callback2()
    //   }
    //   if(!!closeCallback && typeof closeCallback === 'function'){
    //     vm.closeEventFunction = function (){
    //       closeCallback()
    //       vm.close()
    //     }
    //   }
    // },
    open : function (){
      const vm = this
      vm.isShow = true
      vm.$emit('open', false)
    },
    setTitle : function (title){
      this.realTitle = title
    },
    close : function (closeCallback){
      const vm = this
      vm.isShow = false
      if(!!closeCallback && typeof closeCallback === 'function'){
        vm.closeEventFunction = function (){
          closeCallback()
          vm.close()
        }
      }else {
        vm.$emit('close', false)
      }
    },
  },
  data (){
    return {
      isShow : false,
      realType : '',
      realTitle : '',
      realContents : '',
      closeEventFunction : function (){},
      constant : {
        type : {
          alert : '01',
          confirm : '02',
          info : '03',
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
